<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Create Availability Scheme
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <validation-observer
      ref="availabilitySchemeCreateForm"
      #default="{invalid}"
    >
      <b-form @submit.prevent="submitForm">
        <b-card>
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Applicable Period*"
                label-for="h-availabilityScheme-datePeriod"
                label-cols-md="4"
              >
                <b-input-group>
                  <flat-pickr
                    id="h-availabilityScheme-datePeriod"
                    v-model="datePeriod"
                    class="form-control flat-pickr-group"
                    :config="{ wrap: true, mode: 'range', dateFormat: 'd/m/Y', minDate: 'today', disableMobile: true }"
                    @input="datePeriodValidation == true ? datePeriodValidation = false : null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="CalendarIcon"
                      class="cursor-pointer"
                      data-toggle
                      size="18"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-if="datePeriodValidation"
                  class="text-danger"
                >
                  {{ datePeriodError }}
                </small>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <b-form-group
                label="Operating Hours*"
                label-for="h-availabilityScheme-operatingHours"
                label-cols-md="4"
              >
                <div class="inlineElement opening__hours">
                  <b-input-group>
                    <flat-pickr
                      v-model="startTime"
                      class="form-control"
                      :config="{ wrap: true, noCalendar: true, enableTime: true, dateFormat: 'H:i', disableMobile: true }"
                      @input="startTimeValidation == true ? startTimeValidation = false : null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="ClockIcon"
                        class="cursor-pointer"
                        data-toggle
                        size="18"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    v-if="startTimeValidation"
                    class="text-danger"
                  >
                    {{ startTimeError }}
                  </small>
                  <b-input-group>
                    <flat-pickr
                      v-model="endTime"
                      class="form-control"
                      :config="{ wrap: true, noCalendar: true, enableTime: true, dateFormat: 'H:i', disableMobile: true }"
                      @input="endTimeValidation == true ? endTimeValidation = false : null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="ClockIcon"
                        class="cursor-pointer"
                        data-toggle
                        size="18"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    v-if="endTimeValidation"
                    class="text-danger"
                  >
                    {{ endTimeError }}
                  </small>
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="12">
              <b-form-group
                label="Applicable Days*"
                label-for="h-availabilityScheme-applicableDays"
                label-cols-md="2"
                class="applicable_days"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Applicable Days"
                  vid="applicableDays"
                  rules="required"
                >
                  <!-- <b-form-input
                    id="h-availabilityScheme-applicableDays"
                    v-model="applicableDays"
                    placeholder="Applicable Days"
                    :state="(errors.length > 0 || applicableDaysValidation) ? false : null"
                    name="applicableDays"
                    @input="applicableDaysValidation == true ? applicableDaysValidation = false : null"
                  /> -->
                  <b-form-checkbox-group
                    id="h-availabilityScheme-applicableDays"
                    v-model="applicableDays"
                    button-variant="outline-primary"
                    buttons
                    class="spaceBetweenRadio"
                    multiple
                  >
                    <b-form-checkbox
                      v-for="(day, kindex) in optionsChekcbox"
                      :key="kindex"
                      :value="day.value"
                      class="mb-50 customCheckbox"
                    >
                      <feather-icon
                        v-if="applicableDays.includes(day.value)"
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      <span class="align-middle">{{ day.text }}</span>
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="applicableDaysValidation"
                    class="text-danger"
                  >
                    {{ applicableDaysError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6">
              <b-form-group
                label="Timeslots Duration (hr)*"
                label-for="h-availabilityScheme-timeSlotDuration"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Timeslots Duration (hr)"
                  vid="timeSlotDuration"
                  rules="required|between:0,24"
                >
                  <b-form-input
                    id="h-availabilityScheme-timeSlotDuration"
                    v-model="timeSlotDuration"
                    placeholder="Timeslots Duration (hr)"
                    :state="(errors.length > 0 || timeSlotDurationValidation) ? false : null"
                    name="timeSlotDuration"
                    @input="timeSlotDurationValidation == true ? timeSlotDurationValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="timeSlotDurationValidation"
                    class="text-danger"
                  >
                    {{ timeSlotDurationError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <b-form-group
                label="Quantity Per Slot*"
                label-for="h-availabilityScheme-quantityPerSlot"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Quantity Per Slot"
                  vid="quantityPerSlot"
                  rules="required|numeric|min_value:1"
                >
                  <b-form-input
                    id="h-availabilityScheme-quantityPerSlot"
                    v-model="quantityPerSlot"
                    placeholder="Quantity Per Slot"
                    :state="(errors.length > 0 || quantityPerSlotValidation) ? false : null"
                    name="quantityPerSlot"
                    @input="quantityPerSlotValidation == true ? quantityPerSlotValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="quantityPerSlotValidation"
                    class="text-danger"
                  >
                    {{ quantityPerSlotError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Status"
                vid="status"
                rules="required"
              >
                <b-form-group
                  label="Status*"
                  label-for="h-availabilityScheme-status"
                  label-cols-md="4"
                  :state="(errors.length > 0 || statusValidation) ? false : null"
                >
                  <v-select
                    id="h-availabilityScheme-status"
                    v-model="status"
                    label="title"
                    :options="option"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="statusValidation == true ? statusValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="statusValidation"
                    class="text-danger"
                  >
                    {{ statusError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />
          </b-row>
        </b-card>
        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'appointment-activity-types-show', params: { id: $route.params.parent } }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Availability Scheme</span>
            </b-button>
          </li>
        </b-nav>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BInputGroup, BInputGroupAppend, BFormCheckbox, BFormCheckboxGroup, BNav,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import {
  // eslint-disable-next-line import/named
  required, maxValue, minValue, numeric, between,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormCheckboxGroup,
    BForm,
    BButton,
    BNav,
    BInputGroup,
    BInputGroupAppend,

    vSelect,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      datePeriod: '',
      startTime: '',
      endTime: '',
      applicableDays: [],
      timeSlotDuration: '',
      quantityPerSlot: '',
      status: 'active',
      datePeriodError: 'Valid date period is required',
      datePeriodValidation: false,
      startTimeError: 'Valid operating hours is required',
      startTimeValidation: false,
      endTimeError: 'Valid operating hours is required',
      endTimeValidation: false,
      applicableDaysError: 'Valid applicable days is required',
      applicableDaysValidation: false,
      timeSlotDurationError: 'Valid time slot is required',
      timeSlotDurationValidation: false,
      quantityPerSlotError: 'Valid quantity is required',
      quantityPerSlotValidation: false,
      statusError: 'Valid status is required',
      statusValidation: false,
      option: [{ title: 'Active', code: 'active' }, { title: 'Inactive', code: 'inactive' }],
      optionsChekcbox: [
        { text: 'Mon', value: 'Mon' },
        { text: 'Tue', value: 'Tue' },
        { text: 'Wed', value: 'Wed' },
        { text: 'Thu', value: 'Thu' },
        { text: 'Fri', value: 'Fri' },
        { text: 'Sat', value: 'Sat' },
        { text: 'Sun', value: 'Sun' },
      ],

      // validation rules
      required,
      numeric,
      maxValue,
      minValue,
      between,
    }
  },
  methods: {
    submitForm() {
      this.$refs.availabilitySchemeCreateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('datePeriod', this.datePeriod)
          formData.append('startTime', this.startTime)
          formData.append('endTime', this.endTime)
          formData.append('applicableDays', this.applicableDays)
          formData.append('timeSlotDuration', this.timeSlotDuration)
          formData.append('quantityPerSlot', this.quantityPerSlot)
          formData.append('activityType', this.$route.params.parent)
          formData.append('status', this.status)

          this.$http.post('appointment/availability-schemes/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  title: 'Availability Scheme Created.',
                  html: response.data.message || '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  allowOutsideClick: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    if (result.value) {
                      this.$router.push({ name: 'appointment-activity-types-show', params: { id: this.$route.params.parent } })
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'datePeriod') {
                    this.datePeriodError = validationError.msg
                    this.datePeriodValidation = true
                  } else if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  } else if (validationError.param === 'startTime') {
                    this.startTimeError = validationError.msg
                    this.startTimeValidation = true
                  } else if (validationError.param === 'endTime') {
                    this.endTimeError = validationError.msg
                    this.endTimeValidation = true
                  } else if (validationError.param === 'applicableDays') {
                    this.applicableDaysError = validationError.msg
                    this.applicableDaysValidation = true
                  } else if (validationError.param === 'timeSlotDuration') {
                    this.timeSlotDurationError = validationError.msg
                    this.timeSlotDurationValidation = true
                  } else if (validationError.param === 'quantityPerSlot') {
                    this.quantityPerSlotError = validationError.msg
                    this.quantityPerSlotValidation = true
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style scoped>
  .customCheckbox {
    border-right-width: 1px !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border-left-width: 1px !important;
  }

  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }

  .btn-group, .btn-group-vertical {
    position: relative;
    display: contents;
    vertical-align: middle;
  }
</style>
